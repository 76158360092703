import type { NitroFetchOptions } from 'nitropack';
import type { UnwrapRef } from 'vue';
import { computed, ref } from 'vue';
import { z } from 'zod';

import type { AjaxSetup } from '../types/general';
import type { JamServiceResponse } from './useJamService';
import type { JamFormValidationErrors } from './useJamValidation';
import { useJamValidation } from './useJamValidation';

export const useJamForm = <T>(options: {
    defaultData: T;
    validationRules: z.ZodSchema[];
}) => {
    const data = ref<T>(options.defaultData);
    const errors = ref<JamFormValidationErrors>({});

    const { validate: silentValidate, errors: localErrors } =
        useJamValidation();

    const validate = (field?: string) => {
        silentValidate(data.value, options.validationRules);
        if (!field) {
            errors.value = localErrors.value;
            return;
        }

        if (localErrors.value?.[field]) {
            errors.value[field] = localErrors.value[field];
        } else if (errors.value[field]) {
            delete errors.value[field];
        }

        for (const _field in errors.value) {
            if (_field.startsWith(`${field}.`) && !localErrors.value[_field]) {
                delete errors.value[_field];
            }
        }

        for (const _field in localErrors.value) {
            if (_field.startsWith(`${field}.`)) {
                errors.value[_field] = localErrors.value[_field];
            }
        }
    };

    const hasError = computed(() => Object.keys(errors.value).length > 0);

    const submit = async (
        endpoint: string,
        method: NitroFetchOptions<string>['method'],
        setup: AjaxSetup<T>,
    ) => {
        validate();

        if (hasError.value) {
            setup.onValidationError();
            return;
        }

        const response: JamServiceResponse<T> = await $fetch<
            JamServiceResponse<T>
        >(endpoint, {
            body: JSON.stringify(data.value),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: method,
        });

        if (response.status === 'validationFailed') {
            errors.value = response.validationErrors ?? {};
            setup.onValidationError(response);
            return;
        }

        if (response.status === 'error') {
            setup.onFail(response);
            return;
        }

        if (response.data) {
            data.value = { ...response.data } as UnwrapRef<T>;
        }

        setup.onSuccess(response);
    };

    return {
        data,
        errors,
        hasError,
        submit,
        validate,
    };
};
