import { z } from 'zod';

export type JamFormValidationErrors = {
    [key: string]: string;
};

export const useJamValidation = (name: string = 'default') => {
    const errors = useState<{ [key: string]: JamFormValidationErrors }>(
        'jam-form-errors',
        () => {
            return {
                [name]: {},
            };
        },
    );

    const _errors = ref<JamFormValidationErrors>(errors.value[name] ?? {});

    const validate = (data: any, validationRules: z.ZodSchema[]) => {
        errors.value[name] = _errors.value = {};

        validationRules.forEach((rule) => {
            const e = rule.safeParse(data);

            type zodIssue = { message: string; path: (string | number)[] };
            const toIssuePath = (issuePath: (string | number)[]) => {
                return issuePath
                    .map((path) => path + '')
                    .flat()
                    .join('.');
            };

            if (!e.error) {
                return;
            }

            const messages: { [key: string]: string[] } = {};
            e.error.issues.forEach((issue: zodIssue) => {
                const path = toIssuePath(issue.path);
                if (!messages[path]) {
                    messages[path] = [issue.message];
                    return;
                }
                messages[path].push(issue.message);
            });

            for (const key in messages) {
                errors.value[name][key] = _errors.value[key] =
                    messages[key].join(', ');
            }
        });
    };

    const hasError = (path: string): boolean => {
        for (const key in errors.value[name]) {
            if (key === path || key.startsWith(`${path}.`)) {
                return true;
            }
        }
        return false;
    };

    return { errors: _errors, hasError, validate };
};
